import BrowserRouter from '@ameroservices-platform/loppe-boothownerui/fuse/core/BrowserRouter';
import FuseAuthorization from '@ameroservices-platform/loppe-boothownerui/fuse/core/FuseAuthorization';
import FuseLayout from '@ameroservices-platform/loppe-boothownerui/fuse/core/FuseLayout';
import FuseTheme from '@ameroservices-platform/loppe-boothownerui/fuse/core/FuseTheme';
import { SnackbarProvider } from 'notistack';
import { useSelector } from 'react-redux';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { selectCurrLangDir } from '@ameroservices-platform/loppe-boothownerui/app/store/i18nSlice';
import withAppProviders from './withAppProviders';
import { Auth } from './auth';
import AdapterMoment from '@mui/lab/AdapterMoment';
import { LocalizationProvider } from '@mui/lab';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import StoreInit from '@ameroservices-platform/loppe-boothownerui/app/store/StoreInit';
import moment from 'moment-timezone';
import 'moment/locale/da';
import OrganisationChecker from '@ameroservices-platform/loppe-boothownerui/app/OrganisationChecker';

// import axios from 'axios';
/**
 * Axios HTTP Request defaults
 *
 */
// axios.defaults.baseURL = "";
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';

const emotionCacheOptions = {
	rtl: {
		key: 'muirtl',
		stylisPlugins: [],
		insertionPoint: document.getElementById('emotion-insertion-point')
	},
	ltr: {
		key: 'muiltr',
		stylisPlugins: [],
		insertionPoint: document.getElementById('emotion-insertion-point')
	}
};

moment.tz.setDefault('Europe/Copenhagen');
moment.locale('da');

window.addEventListener(
	'CookieScriptLoaded',
	function (e) {
		if (CookieScript.instance.currentState().categories.includes('functionality')) {
			window.clarity('consent');
			console.log('yay accepted');
		}
	},
	false
);

window.addEventListener(
	'CookieScriptAccept',
	function (e) {
		if (e.categories.includes('functionality')) {
			window.clarity('consent');
			console.log('yay accepted');
		}
	},
	false
);

window.addEventListener(
	'CookieScriptAcceptAll',
	function (e) {
		window.clarity('consent');
		console.log('yay accepted');
	},
	false
);

const App = () => {
	const langDirection = useSelector(selectCurrLangDir);

	return (
		<CacheProvider value={createCache(emotionCacheOptions[langDirection])}>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<Auth>
					<StoreInit>
						<BrowserRouter>
							<FuseAuthorization>
								<FuseTheme>
									<OrganisationChecker>
										<SnackbarProvider
											maxSnack={5}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'right'
											}}
											classes={{
												containerRoot: 'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99'
											}}
										>
											<FuseLayout />
										</SnackbarProvider>
									</OrganisationChecker>
								</FuseTheme>
							</FuseAuthorization>
						</BrowserRouter>
					</StoreInit>
				</Auth>
			</LocalizationProvider>
		</CacheProvider>
	);
};

export default withAppProviders(App)();
